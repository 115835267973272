import React from "react"
import ReactDOM from "react-dom"
import "./index.css"
import App from "./App"
import { MatomoProvider, createInstance } from "@datapunt/matomo-tracker-react"

const instance = createInstance({
  urlBase: "https://analytics.faifarms.com/",
  siteId: 4,
  userId: "UID76903202", // optional, default value: `undefined`.
  disabled: false, // optional, false by default. Makes all tracking calls no-ops if set to true.
  heartBeat: {
    // optional, enabled by default
    active: true, // optional, default value: true
    seconds: 10, // optional, default value: `15
  },
  linkTracking: false, // optional, default value: true
  configurations: {
    // optional, default value: {}
    // any valid matomo configuration, all below are optional
    disableCookies: true,
    setSecureCookie: true,
    setRequestMethod: "POST",
  },
})

ReactDOM.render(
  <React.StrictMode>
    {/* <MatomoProvider value={instance}> */}
    <App />
    {/* </MatomoProvider> */}
  </React.StrictMode>,
  document.getElementById("root")
)
