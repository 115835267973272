export const setTenantBranding = () => {
  try {
    const branding = JSON.parse(localStorage.getItem("branding"))
    const root = document.documentElement
    root.style.setProperty("--color-primary", branding.colors.primary)
    root.style.setProperty("--color-secondary", branding.colors.secondary)
    root.style.setProperty("--color-info", branding.colors.info)
    root.style.setProperty("--color-warning", branding.colors.warning)
    root.style.setProperty("--color-success", branding.colors.success)
    root.style.setProperty("--color-danger", branding.colors.danger)
    root.style.setProperty("--color-text-primary", branding.text.color.primary)
    root.style.setProperty(
      "--color-text-secondary",
      branding.text.color.secondary
    )
  } catch (error) {
    console.log("Unable to load styles")
  }
}

export const tenantLogo = (size) => {
  try {
    const branding = JSON.parse(localStorage.getItem("branding"))
    return branding.images.logo[size]
  } catch (error) {
    console.log("Unable to get logo")
  }
}
