import React, { useEffect, useState } from "react"
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom"
import { withStore, useStore } from "react-context-hook"
import { setTenantBranding } from "./BrandedStyles"
import "./App.css"

const loading = () => (
  <div className="main">
    <div className="main-container">
      <h5 style={{ fontSize: "16pt" }}>Loading...</h5>
    </div>
  </div>
)

const Login = React.lazy(() => import("./views/Login"))
const Dashboard = React.lazy(() => import("./views/Dashboard"))
const MapRenderer = React.lazy(() => import("./views/MapRenderer"))
const PageNotFound = React.lazy(() => import("./views/PageNotFound"))

const authenticate = async (signal) => {
  return fetch(`${process.env.REACT_APP_API_HOST}/auth/authenticate`, {
    credentials: "include",
    signal,
  })
    .then((r) => r.json())
    .then((res) => {
      if (res.status === 200) return res
      return false
    })
}

setTenantBranding()

function PrivateRoute({ children, ...rest }) {
  const [authd, setAuthd] = useState(null)
  const [tenant, setTenant] = useStore("tenant", null)
  const [userAccount, setUserAccount] = useStore("userAccount", null)

  useEffect(() => {
    //const abortController = new AbortController();
    authenticate()
      .then((res) => {
        setAuthd(res)
        setTenant(res?.data?.tenant?.identifier)
        setUserAccount(res?.data)
      })
      .catch((err) => {
        console.log(err)
        setAuthd(false)
      })

    // return () => {
    //   abortController.abort()
    // }
  }, [])

  if (authd === null)
    // Stops us starting to load private page before we know auth status
    return loading()
  else
    return (
      <Route
        {...rest}
        render={() => {
          return authd ? children : <Redirect to="/login" />
        }}
      />
    )
}

function App() {
  return (
    <Router>
      <React.Suspense fallback={loading()}>
        <Switch>
          <PrivateRoute path="/dashboard">
            <Route
              exact
              path="/dashboard"
              name="Dashboard"
              render={(props) => <Dashboard {...props} />}
            />
          </PrivateRoute>
          <PrivateRoute path="/map/:layer_group_uid">
            <Route
              exact
              path="/map/:layer_group_uid"
              name="Map"
              render={(props) => <MapRenderer {...props} />}
            />
          </PrivateRoute>
          <Route
            path="/login"
            name="Login"
            render={(props) => <Login {...props} />}
          />
          <Route
            exact
            path="/"
            render={(props) => <Redirect to="/dashboard" />}
          />
          <Route
            name="PageNotFound"
            render={(props) => <PageNotFound {...props} />}
          />
        </Switch>
      </React.Suspense>
    </Router>
  )
}

export default withStore(App)
